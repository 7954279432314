<template>
	<div class="row">
		<div class="col-12">
            <h4>{{ $t('trigger.entity_type.suppression_acte') }}</h4>
        </div>
        <div class="col-12">
			<CustomTable
				ref="table"
				id_table="acte_to_delete"
				:items="actes"
				primaryKey="actes_id"
				:useTransformerSort="true"
				:buttonActionEventOnly="true"
			/>
        </div>
        <div class="text-center mt-3 col-12">
            <button @click="closeShutter" class="btn btn-secondary rounded-pill mr-4">
                <font-awesome-icon :icon="['fal', 'times']" /> {{ $t('acte.trigger.ignorer') }}
            </button>
        </div>
	</div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import Navigation from "@/mixins/Navigation.js";
    import ActesMixin from "@/mixins/Actes.js";
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'

	export default {
		name: 'SuppressionActe',
		mixins: [Navigation, ActesMixin, Shutter, ShutterTriggers],
		props: {
			horses_ids: { type: Array, default: () => [] },
            date_acte: { type: Date, default: () => new Date() },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] }
		},
		data () {
			return {
				actes: [],
				events_tab: {
					'TableAction::DeleteActes::Trigger': this.deleteActesTrigger,
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const horse_ids = this.horses_ids.map(id => parseInt(id))
				const actes = await this.getActesByHorses(horse_ids)
				this.actes = actes.filter(a => a.statut == 0)
			},

			async deleteActesTrigger(actes) {
				await this.applyDeleteActes(actes)
				EventBus.$emit('TableAction::stopSpin', true)
				this.closeShutter()
			},

			closeShutter() {
				if(this.next.length > 0) {
                    const triggered = this.next[0]
                    this.next.shift()
                    this.shutterPanel().close('suppression_acte')
                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next)
                } else { 
                    let horse_id = this.$sync.replaceWithReplicated('horse', this.horses_ids[0])
                    this.$router.push({ name: 'HorseFichePlanning', params: {horse_id} })
                }
			}
		},

		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>